@import "variables";
@import "text";
@import "container";

.overlay {
    z-index: 998;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0; left: 0;
    background-color: $overlay;
}

.modal {
    z-index: 999;
    border-radius: $rad;
    box-shadow: $box-shadow; 
    transition: opacity 0.3s;
    box-sizing: border-box;
    padding: $padding-lg;

    @extend .window;
    @extend [variant="white"];
}