@import "variables";

* { font-family: "Lato", sans-serif }

[variant="primary"]     { background-color: $green; color: $white }
[variant="secondary"]   { background-color: $light; color: $dark }
[variant="danger"]      { background-color: $red; color: $white }
[variant="dark"]        { background-color: $dark; color: $white }
[variant="white"]       { background-color: $white; color: $dark }

[align="center"]        { text-align: center }
[align="left"]          { text-align: left }
[align="right"]         { text-align: right }

.text-primary   { color: $green }
.text-secondary { color: $light }
.text-muted     { color: $mid }
.text-danger    { color: $red }

h1      { font-size: 30px }
h2      { font-size: 26px }
h3      { font-size: 22px }
h4      { font-size: 18px }
h5      { font-size: 16px }
h6      { font-size: 14px }
p       { font-size: 16px }
a, 
b, 
strong  { font-weight: 900 }

a       { color: inherit }

.status {
    text-align: center;

    @extend .text-muted
}