@import "variables";
@import "text";

$input-height: 45px;

label {
    display: block;
    margin-bottom: 10px;
}

input, textarea, select, .form-open-list { 
    outline: none;
    height: $input-height;

    & + [data-icon] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        color: $green;
        z-index: 1;
        // Ignore clicks
        pointer-events: none;
    }
}

textarea { resize: vertical; min-height: 150px }

input,
.form-field-overlay,
.form-open-list,
textarea, select {
    width: 100%;
    line-height: 16px;
    font-size: 16px;
    box-sizing: border-box;
    padding: $padding-lg;
    box-shadow: $box-shadow;
    transition: all 400ms;
    border-radius: $rad;
    appearance: none;
    border: none;
    display: block;
    margin-bottom: 10px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0);

    &:focus { 
        box-shadow: $no-box-shadow;
        border-color: $green;
    }
}

// This is to match with the overlay height
input[type="file"] { 
    opacity: 0; 
    cursor: pointer; 
}

.form-group {
    margin-bottom: 10px;
}

.form-image-preview {
    margin-bottom: 10px;
    max-width: 100%;
}

.form-field-overlay {
    top: 0; left: 0;
    position: absolute;
    width: 100%;
    pointer-events: none;
    margin: 0;
    z-index: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding-right: 50px;
}

input[type="checkbox"] { 
    opacity: 0;  
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.form-pseudo-checkbox {
    position: absolute;
    top: 0; left: 0;
    pointer-events: none;
    width: 30px;
    height: 30px;
    border-radius: $rad;
    background-color: $white;
    overflow: hidden;
    box-shadow: $box-shadow;

    .form-pseudo-check {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        background-color: $green;
        text-align: center;
        line-height: 30px;
        color: $white;
        display: none;
    }

    &.checked .form-pseudo-check { display: block }
}

.form-field-desc {
    margin: 0 0 10px;
    font-size: smaller;
    font-style: italic;

    @extend .text-muted;
}

.form-lookup {
    position: relative;

    .suggestions {
        position: absolute;
        top: 50px; left: 0;
        border: $border-thin;
        background-color: $white;
        box-sizing: border-box;
        padding: $padding-sm;
        max-height: 300px;
        overflow-y: scroll;

        > p {
            margin: 5px 0;
        }
    }
}