.print-container {
  z-index: 999999;
  background-color: white;
  //position: absolute;
  //left: 0;
  //top: 0;
  width: 100vw;
}

.print-body {
  width: 100%;
}

.print-footer {
  bottom: 0;
}

.page-header,
.page-header-space {
  height: 80px;
}

.page-footer,
.page-footer-space {
  height: 100px;
}

.page {
  page-break-after: always;
}

.page-header,
.page-footer {
  position: fixed;
  width: 100%;
}

.page-header {
  top: 0;

  .title {
    margin: 0;
    line-height: 80px;
    text-align: center;
  }
  .logo {
    position: absolute;
    left: 0;
    top: 20px;
    height: 40px;
  }
}

.page-footer {
  bottom: 0;
}

@page {
  margin: 0 10mm;
  size: auto;
}

@media print {
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
