@import "variables";
@import "header";

.section {
    margin-bottom: $margin;
    border-bottom: $border-thin;
}

.section-title {
    @extend .header-subtitle;

    margin: 0 0 10px;
}