@import "variables";
@import "text";

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

a .panel::after {
    position: absolute;
    right: 10px; top: 50%;
    transform: translateY(-50%);
    width: 20px;
    zoom: 0.75;
    display: block;
    content: url(../assets/icons/chevron-right.svg);

    &.check { content: url(../assets/icons/check.svg) }
}

.panel {
    border-radius:  $rad;
    box-shadow:     $box-shadow;
    box-sizing:     border-box;
    padding:        $padding-sm;
    //overflow:       hidden; // To preserve border radius
    position:       relative;
    margin-bottom:  10px;

    @extend [variant="white"];
}

.panel-image {
    width: 70%;
    margin: 10px auto 0;
}

.panel-title, .panel-body { 
    margin: 8px 0;
    box-sizing: border-box;
}

.panel-title { 
    padding: 5px 14px 5px;
    font-size: 18px 
}

.panel-body { 
    font-weight: initial;
    padding: 0 14px 5px;

    p {
        margin: 0 0 5px;
    }
}

.panel-header, .panel-footer { 
    background-color: $light;
    padding: $padding-lg;

    > p { margin: 5px 0 }
}

.panel-footer { text-align: right }

.panel-notif {
    border-radius: 30px;
    min-width: 25px;
    text-align: center;
    position: absolute;
    top: 5px; right: 5px;
    box-sizing: border-box;
    padding: $padding-sm;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
}

@include media($sm, 99999px) {
    // .panel-title, .panel-body {
    //     display: inline-block;
    //     vertical-align: middle;
    //     width: 50%;
    // }   
    .panel-image {
        margin: 0;
        width: 100%;
    }
}

.panel-label {
    line-height: 14px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: $padding-sm;
    border-radius: 50%;
    position: absolute;
    right: -5px; top: -5px;
    z-index: 10;

    //@extend [variant="danger"];
}