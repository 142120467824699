@import "variables";

input[type="button"], input[type="submit"], button {
    outline: none;
    box-sizing: border-box;
    padding: $padding-lg;
    border-radius: $rad;
    box-shadow: $no-box-shadow;
    border: none;
    font-size: 16px;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.2s;
    filter: brightness(100%);

    &:hover     { box-shadow: $box-shadow }
    &:active    { filter: brightness(75%) }
}