@import "variables";

@mixin breakpoints($margin: 0px) {
    @include media(0, $xs - 1px)        { width: calc(100% - #{$margin}); @content; }
    @include media($xs, $sm - 1px)      { width: $xs - $margin; @content; }
    @include media($sm, $md - 1px)      { width: $sm - $margin; @content; }
    @include media($md, $lg - 1px)      { width: $md - $margin; @content; }
    @include media($lg, $xl - 1px)      { width: $lg - $margin; @content; }
    @include media($xl, 99999px)        { width: $xl - $margin; @content; }
}

.container {
    @include breakpoints() {
        margin: 0 auto;
        box-sizing: border-box;
        padding: $padding-lg;
        position: relative;

        &:last-child { margin-bottom: 10px }
    }
}

.window {
    // 14 px either side
    @include breakpoints(28px) {
        position: absolute;
        max-width: 400px;
        left: 0; right: 0;
        margin: 0 auto;
    }
}