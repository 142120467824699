@import 'variables';

$nav-height: 70px;

nav {
  position: relative;
  overflow: hidden;
  min-height: $nav-height;
  clear: both;

  .nav-item-container {
    width: 100%;
  }

  .nav-buttons {
    height: $nav-height;
  }

  .nav-back,
  .nav-burger {
    margin: 22.5px;
    font-size: 25px;
    line-height: 25px;
  }

  .nav-back {
    float: left;
  }
  .nav-burger {
    float: right;
  }

  h3 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ul {
    list-style-type: none;
    box-sizing: border-box;
    padding: 0 10px;
    margin: 0;
    transition: height 0.23s ease-in-out;
    overflow: hidden;
    height: 0;
  }

  a {
    text-decoration: none;
  }

  li {
    display: block;
    height: 40px;
    box-sizing: border-box;
    padding: 12px 0;
  }

  p {
    font-weight: 400;
    margin: 0;
  }
}
