@import "variables";

html, 
body { 
    margin: 0px; 
    padding: 0px;
    background-color: $light;
    display: block;
    color: $dark;
    min-height: 100vh;
}

img {
    display: block;

    &[src=""] {
        display: none;
    }
}

hr { 
    border: .5px solid $mid;
    margin-block-start: 10px;
    margin-block-end: 10px;
}

.hide {
    display: none;
}

table { page-break-inside:auto }
tr    { page-break-inside:avoid; page-break-after:auto }
thead { display:table-header-group }
tfoot { display:table-footer-group }