@import "variables";

.columns {
    width: auto;
    margin: 0 auto;

    .panel {
        @include media(0, $sm - 1) { width: 100% }
        @include media($sm, $md - 1) { width: calc(50% - 5px) }
        @include media($md, 99999px) { width: calc((100% / 3) - 7.5px) }
    }
}



