@import 'variables';

table {
  width: 100%;
  margin-bottom: 10px;
  border: $border;
  border-collapse: collapse;

  thead {
    th,
    td {
      background-color: $light;
    }
  }

  tbody tr {
    box-sizing: border-box;

    th,
    td {
      padding: $padding-lg;
      text-align: left;
      border: $border-thin;
    }

    &:nth-child(odd) {
      background-color: $white;
    }
    &:nth-child(even) {
      background-color: $light;
    }
  }

  &.print {
    border: none;
    width: 100%;

    tr {
      background-color: $white !important;
    }

    thead,
    tfoot {
      tr {
        th,
        td {
          border: none !important;
        }
      }
    }
  }
}
