// Colors
$overlay: #4d4d4d90;
$dark: #4d4d4d;
$dark-overlay: #4d4d4d26;
$mid: #969595;
$mid-overlay: #694a4a50;
$light: #f4f4f4;
$white: #ffffff;
$green: #00756c;
$red: #ff4e4e;

$box-shadow: 0 0 10px $dark-overlay;
$dark-box-shadow: 0 0 7.5px $dark;
$no-box-shadow: 0 0 0px $dark-overlay;

// Border, Padding, Margin
$border: 2px solid $mid;
$border-thin: 1px solid $mid-overlay;
$rad: 10px;
$margin: 10px;
$padding-sm: 5px 8px;
$padding-lg: 10px 14px;

// Breakpoints
$xs: 550px;
$sm: 750px;
$md: 950px;
$lg: 1100px;
$xl: 1400px;

// Mixins
@mixin media($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}
