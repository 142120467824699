@import "variables";
@import "text";

.header {
    padding: 10px 0;
    margin-bottom: $margin * 2;
    border-bottom: $border-thin;
}

.header-title {
    margin: 0 0 5px;
}

.header-subtitle {
    margin: 0;
    text-transform: uppercase;
    
    @extend .text-muted
}